import $ from "jquery";
import {initAjaxFormMap, apiLoaded} from "../libs/@elements/ajax-form-map/ajax-form-google";
import {createAjaxForm} from "../libs/@elements/ajax-form";
import initMap, {showInfoBoxByMarker, setMarker, centerMap } from "../libs/@elements/google-map";
import {getConfigValue} from '../libs/@elements/config-utils';
import formDataEntries from 'form-data-entries';
import fetch from '../libs/@elements/fetch';
import {redirectClientTo} from './redirectHandler';
import initModulesInScope from "../libs/@elements/init-modules-in-scope";

export function initInScope($scope) {
    initGoogleMapForm();
    function initGoogleMapForm(){
        let $googleMaps = $scope.find(selectors.map.base);
        if ($googleMaps && $googleMaps.length) {
            $googleMaps.each(function (index, element) {
                let $container = $(element).closest(selectors.ajaxForm.base);
                if( !$container.length ) {
                    $container = $scope.find( selectors.ajaxForm.base);
                }
                let $mapCanvas = $(element).find(selectors.map.canvas);

                let mapObj = null;
                let ajaxFormApi = null;
                let $form = $container.find(selectors.ajaxForm.form);
                if( $form.length ) {

                    ajaxFormApi = createAjaxForm($container, selectors.ajaxForm, {
                        onSubmit: x => loadPois( $container, mapObj ),
                        onStatePop: x => loadPois( $container, mapObj )
                    });

                    $container.on( 'success.ajax-form', function() {
                        // "workaround" visibility switch for nav - may initially be hidden
                        let tabNav = $container.find( 'ul.nav[role="tablist"]');
                        let tabContent = $container.find( '.tab-content');
                        if( tabNav.length && tabContent.length ) {
                            // note: may verify if any results are available at all
                            if( tabNav.hasClass( 'd-none') ) {
                                tabNav.removeClass( 'd-none');
                            }
                        }
                    } );

                    let $inputs = $form.find(':input').not( 'button');
                    let $clearTriggerInputs = $inputs.filter( '[data-clear-on-change]' );
                    if( $clearTriggerInputs.length ) {
                        $clearTriggerInputs.on( 'change', function() {
                            let $sourceInput = $(this);
                            let targetInputName = $sourceInput.data( 'clearOnChange');
                            if( targetInputName ) {
                                // note: only works with single input for now - may extend via iteration

                                // always look in current dom - as inputs (dom) may change
                                let targetInput = $form.find( ':input[name="' + targetInputName + '"]' );
                                if( !targetInput.length ) {
                                    // fallback to multi-select format
                                    targetInput = $form.find( ':input[name="' + targetInputName + '[]"]' );
                                }

                                if( targetInput.length ) {
                                    if( targetInput.is( ':checkbox') || targetInput.is( ':radio') ) {
                                        targetInput.prop('checked', null);
                                    } else {
                                        targetInput.val( '');
                                    }
                                }
                            }
                        } );
                    }
                }

                function setupMapOnceLoaded() {
                    mapObj = createMap( $container, $mapCanvas );
                    loadPois( $container, mapObj );
                }

                /**
                 * load map (pois) once on (first) click
                 */
                function initializeMap() {
                    return apiLoaded().then(function () {
                        apiLoaded().then(function () {
                            setupMapOnceLoaded();
                        });
                    });
                }

                /**
                 * center-map after it is shown
                 * required due too filtering via list and map-tab is hidden
                 * should be skipped on first execution as above event has not yet finished
                 */
                function reCenterMap() {
                    if( mapObj ) {
                        // note: may be skipped if pois did not change but this would need to be tracked
                        centerMap( mapObj );
                    }
                }

                // todo: issue with bootstrap + jquery click event
                let selectorStrategy = false;
                if( selectorStrategy ) {
                    $scope.one( 'show.bs.tab', '.js-load-map', initializeMap );
                    $scope.on( 'shown.bs.tab', '.js-load-map', reCenterMap );
                } else {
                    let $mapTrigger = $scope.find('.js-load-map');
                    if( $mapTrigger.hasClass( 'js-bs-click-workaround') ) {
                        $mapTrigger.one('click', function( evt ) {
                            initializeMap();
                            $mapTrigger.on('click', reCenterMap );
                        } );

                    } else {
                        $mapTrigger.one('show.bs.tab', initializeMap );
                        $mapTrigger.on('shown.bs.tab', reCenterMap );
                    }

                }
            });
        }
    }
}

const selectors = {
    ajaxForm: {
        base: '.js-ajax-form-map',
        result: '.js-ajax-form-map__result',
        loading: '.js-ajax-form-map__loading',
        notifications: '.js-ajax-form-map__notifications',
        form: '.js-ajax-form-map__form',
        additionalForm: '.js-ajax-form-map__additional-form',
        errorArea: '.js-ajax-form-map__error-area',
        retry: '.js-ajax-form-map__retry',
        link: '.js-ajax-form-map__link'
    },
    map: {
        base: '.js-ajax-form-map__map',
        canvas: '.js-ajax-form-map__map__canvas'
    }
};

const clusteringOptions = {
    default: {
        styles: [{
            height: 52,
            url: "/rent2022/static/img/map/group-active.svg?v=2",
            width: 52,
            textSize: 17,
            textColor: "#FFF"
        }]
    },
    active: {
        styles: [{
            height: 52,
            url: "/rent2022/static/img/map/group-active.svg?v=2",
            width: 52,
            textSize: 17,
            textColor: "#FFF"
        }]
    }
};

const mapOptions = {
    styles: [
        /*{
            stylers: [
                { saturation: -95 }
            ]
        },*/{
            //hide businesses, which include other lodgings
            "featureType": "poi.business",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }

    ]
};

function getMapSize() {
    let mapSize = -200
    if(matchMedia('(max-width: 767px)').matches) {
        mapSize = -150
    }
    return mapSize;
}


function createPoiStyles() {
    return  {
        default: {
            default: {
                url: '/rent2022/static/img/map/location-inactive.svg?v=3.1',
                size: new google.maps.Size(51, 65),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(25, 33)
            },
            active: {
                url: '/rent2022/static/img/map/location-active.svg?v=3.1',
                size: new google.maps.Size(51, 65),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(25, 33)
            }
        }
    };
}




function createInfoBoxOptions( mapSize ) {
    mapSize = mapSize || getMapSize();
    return {
        alignBottom: true,
        disableAutoPan:true,
        pixelOffset: new google.maps.Size(mapSize, -50),
        boxStyle: {
            width: matchMedia('(max-width: 767px)').matches ? '300px' : '400px',
            background: "#fff",
            padding: "0px"
        },
        closeBoxURL: '/rent2022/static/img/closemap.gif'
    };
}

function createMap( $container, $mapCanvas ) {
    let mapSize = getMapSize();
    const infoBoxOptions = createInfoBoxOptions( mapSize );
    const poiStyles = createPoiStyles();

    let initMapOptions = {
        element: $mapCanvas[0],
        mapOptions: mapOptions,
        poiStyles,
        infoBoxOptions,
        clustering: true,
        clusteringOptions,
        onActivateMarker: (marker, mapObj) => onActivateMarker($container, marker, mapObj),
        onDeactivateMarker: (marker, mapObj) => onDeactivateMarker($container, marker, mapObj)
    };
    return initMap( initMapOptions );
}

function infoBoxHandleWidgetClick( $infoBox, $trigger ) {
    // forward click to item in list
    let locationId = $trigger.attr('data-location-id');
    let $teaser = $trigger.closest('.tab-content').find('[data-location-teaser="' + locationId + '"]');
    if( $teaser.length ) {
        // any target
        let targetButton = $teaser.find('.js-trigger-widget-target');
        if( !targetButton.length ) {
            // booking-widget
            targetButton = $teaser.find('.js-booking-widget__btn');
        }
        if( targetButton.length ) {
            // trigger click
            targetButton.click();

            if( targetButton.is('a') ) {
                // client-side redirect for regular link
                redirectClientTo( targetButton.attr('href') )
            }
        }
        return true;
    } else {
        return false;
    }

}

function onActivateMarker($container, marker, mapObj) {
    let infoBoxPromise = showInfoBoxByMarker(marker, marker.detailInfo, mapObj);

    infoBoxPromise.then(function() {
        // triggered from infobox after dom has been attached to document
        google.maps.event.addListener( marker.infoBox, 'domready', function() {
            const gmapsInfoBox = this;
            // infobox property element
            let $infoBox = $(gmapsInfoBox.div_);

            // forward click to entries inside list
            let $widgetClick = $infoBox.find( '.js-trigger-widget-click');
            if( $widgetClick.length ) {
                $widgetClick.click( function( e ) {
                    e.preventDefault();
                    infoBoxHandleWidgetClick( $infoBox, $(this) );
                } );
            }
            // may extend to any module
            let $ajaxModalTrigger = $infoBox.find( '.js-ajax-modal-btn');
            if( $ajaxModalTrigger.length ) {
                // note: bs-modal is handled but custom js not must be initialized
                initModulesInScope( $infoBox );
            }

        } );
    } )

    // executed when info-box lib has been loaded and opened - but dom may not be ready yet
    /*
    $.when( infoBoxPromise ).done(function( infoBox ) {
        console.log( 'box-loaded?')
    });
    */
}


function onDeactivateMarker($container, marker, mapObj) {
    //console.log($container, marker, mapObj);
}

function loadPois( $container, mapObj ) {
    if( !mapObj ) {
        return;
    }
    const configContainer = '_mapData';
    let preBuiltPois = null;
    if( window[configContainer] ) {
        // getConfigValue will throw up if container is not defined
        preBuiltPois = getConfigValue( { name: 'pois', optional: true}, configContainer );
    }

    if( typeof preBuiltPois !== 'undefined' && preBuiltPois !== null && Array.isArray( preBuiltPois ) && preBuiltPois.length ) {
        setMarker(mapObj, preBuiltPois);
    } else {
        fetchPois( $container, mapObj );
    }
}
function fetchPois( $container, mapObj ) {
    if( !mapObj ) {
        return;
    }

    let $loading = $container.find(selectors.ajaxForm.loading);
    let mapUrl = $container.data('form-map-url');
    let $form = $container.find(selectors.ajaxForm.form);
    if( mapUrl ) {

        let fetchOptions = {
            method: 'GET',
            headers: {
                ajax: 1 // add ajax header to differentiate between and ajax requests and page request.
                // If this header is not set, browser back could lead the browser to use the cached result of this request as page response.
            }
        };
        if( $form.length ) {
            fetchOptions.body = new URLSearchParams(formDataEntries($form[0]))
        }
        if( $loading.length ) {
            $loading.attr('hidden', null);
        }

        fetch( mapUrl, fetchOptions ).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.success) {
                setMarker(mapObj, res.pois);
            }
        }).catch((error) => {
            console.error("loading data-form-map-url failed:" + error);
        }).finally(() => {
            if( $loading.length ) {
                $loading.attr('hidden','hidden');
            }
        });

    }
}



