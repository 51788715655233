"use strict";

import 'select2';
import {translate} from '../libs/@elements/translations';

function getPlaceHolder( $container ) {
    if( $container.attr('data-placeholder') ) {
        return $container.attr('data-placeholder');
    } else
    if( $container.is('select') ) {
        let prefix = 'form.';
        // note: filter-forms use form.filter - but currently no way to detect
        let type = $container.prop( 'required') ? 'option-required' : 'option-optional';
        let key = prefix + type;
        let value = translate( key );
        if( value !== key ) {
            return value;
        }
    }
    // fallback to empty default
    return '';
}

function getSelectOptions( $container ) {
    let noResults = $container.attr('data-error-msg');
    return  {
        theme: 'bootstrap-5',
        placeholder: getPlaceHolder( $container ),
        "language": {
            "noResults": function(){
                return noResults;
            }
        },
        minimumResultsForSearch: 10,
        selectionCssClass: $container.attr('data-class') || ''
    };
}

export function initInScope($scope) {
    let $select = $scope.find('.js-select-two')

    $select.each(function () {
        $(document).on('select2:open', () => {
            (list => list[list.length - 1])(document.querySelectorAll('.select2-container--open .select2-search__field')).focus()
        });

        let $container = $(this);
        let options = getSelectOptions( $container );
        $container.select2( options );
    });

}