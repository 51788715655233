import {isParsleyForm, isValid} from "../parsley-bootstrap-validation";
import {getPrefixedDataSet} from "../data-set-utils";
import $ from "jquery";
import 'url-polyfill';
import {getElementBySelector, getElementsBySelectorObject} from "./selector-util";
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '../fetch'; // IE10 Polyfill
import {debounce} from "debounce";
import asyncAppend from '../async-append';
import formDataEntries from 'form-data-entries';

const defaultSelectors = {
    base: '.js-ajax-form',
    result: '.js-ajax-form__result',
    loading: '.js-ajax-form__loading',
    notifications: '.js-ajax-form__notifications',
    form: '.js-ajax-form__form',
    additionalForm: '.js-ajax-form__additional-form',
    errorArea: '.js-ajax-form__error-area',
    retry: '.js-ajax-form__retry',
    link: '.js-ajax-form__link',
    reset: '.js-ajax-form__reset'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: false,
    fetchHeaders: {}
};

export function createInitInScope(options = defaultOptions, selectors = defaultSelectors) {
    return function ($scope) {
        return getElementBySelector(selectors.base, $scope).each(function () {
            createAjaxForm($(this), {...defaultSelectors, ...selectors}, options);
        });
    }
}

export function createAjaxForm($baseElement, selectors = defaultSelectors, options = defaultOptions) {
    let $elements = getElementsBySelectorObject({...defaultSelectors, ...selectors}, $baseElement);

    let pendingRequest = null;

    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('ajax-form', $baseElement)
    };


    addSubmitHandler();
    if( options.addUrlParams ) {
        //replace initial state
        history.replaceState({
            'ajaxFormUrl': $elements.form.data('action') || $elements.form.attr('action'),
            'ajaxFormFormDataEntries': getFormDataEntries(),
            'ajaxFormMethod': 'GET',
            'ajaxFormHistoryUrl': location.href
        }, document.title, location.href);
    }

    function addSubmitHandler($form = $elements.form) {
        $form.on('submit', function (evt) {
            evt.preventDefault();
            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            submitForm($(this));
        });

        if (options.submitOnChange) {
            $form.on('change', debounce(function () {
                submitForm($(this));
            }, 200));
        }
    }


    //window addEventListener 'popstate' and load content from history.state
    if( options.addUrlParams ) {
        window.addEventListener('popstate', function (evt) {
            if (options.addUrlParams && evt.state?.ajaxFormUrl) {

                setInputState($elements.form, evt.state.ajaxFormFormDataEntries);

                let params = new URLSearchParams(evt.state.ajaxFormFormDataEntries);

                pendingRequest = load(evt.state.ajaxFormUrl, evt.state.ajaxFormMethod, params, evt.state.ajaxFormHistoryUrl, true);
                pendingRequest
                    .then(() => pendingRequest = null)
                    .catch((error, requestState) => {
                        if ((!error || error.name !== 'AbortError') // native fetch abort
                            && requestState !== 'abort') { // jquery abort
                            pendingRequest = null;
                        }
                    });

                if (options.onStatePop) {
                    call(options.onStatePop, {
                        $element: $baseElement,
                        $elements,
                        options,
                        formData: getFormData()
                    });

                }

            }
        });
    }


    $elements.additionalForm.on('submit', function (evt) {
        evt.preventDefault();
        submitForm($(this));
    });

    $elements.additionalForm.each((index, form) => {
        if (options.submitOnChange || $(form).data('ajax-form-submit-on-change')) {
            $(form).on('change', debounce(function () {
                submitForm($(this));
            }, 200));
        }
    });

    $elements.retry.on('click', function (evt) {
        evt.preventDefault();

        if (lastLoadParams) {
            load(...lastLoadParams);
        }
    });

    // Links
    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));

    function addLinkClickHandler($links) {
        $links.on('click', function (evt) {
            evt.preventDefault();

            let href = $(this).data('href') || $(this).attr('href');
            let action = $elements.form.data('action') || $elements.form.attr('action');
            let params = new URL(href, location.origin).searchParams;

            pendingRequest = load(action, 'GET', params, href);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    // Reset buttons
    addResetHandler(getElementBySelector(selectors.reset, $baseElement));

    function addResetHandler($resetButtons) {
        $resetButtons.on('click', function (evt) {
            let $inputs = $elements.form.find(':input');
            let resetName = $(evt.target).data('reset-name');
            let resetValue = $(evt.target).data('reset-value');
            evt.preventDefault();

            if (resetName) {
                let selectorName = resetName
                    .split(',')
                    .map(name => `[name="${name.trim()}"]`)
                    .join(', ');

                $inputs = $inputs.filter(selectorName);
            }

            if (resetValue) {
                let selectorValue = resetValue.toString()
                    .split(',')
                    .map(value => `[value="${value.trim()}"]`)
                    .join(', ');

                $inputs = $inputs.filter(selectorValue);
            }

            $inputs.filter(':not(:input[type="checkbox"], :input[type="radio"])').val('');
            $inputs.filter(':input[type="radio"], :input[type="checkbox"]').prop('checked', null);

            $inputs.trigger('change');
            $baseElement.trigger('reset.ajax-form');
        });
    }

    let lastLoadParams = null; // for retry
    function load(url, method = "GET", params, historyUrl, statePopped = false) {
        lastLoadParams = [url, method, params, historyUrl];

        if (options.addUrlParams && !statePopped) {
            history.pushState({
                'ajaxFormUrl': url,
                'ajaxFormFormDataEntries': getFormDataEntries(),
                'ajaxFormMethod': method,
                'ajaxFormHistoryUrl': historyUrl.toString()
            },
                document.title,
            historyUrl || url);
        }

        // add base url to params (path)
        params.append('baseUrl', location.pathname);

        if (method.toUpperCase() === "GET") {
            params.append('ajax', 1);
            // Add ajax param to differentiate between and ajax requests and page request.
            // Otherwise Chrome caches these results as normal pages and returns them from cache if the back button is pressed
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                ...options.fetchHeaders
            },
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });

        $baseElement.trigger('fetch.ajax-form', request);


        let $targetsByResultId = {};
        $elements.result.toArray().forEach((element) => {
            let resultId = $(element).data('result-id') || 'default';
            if ($targetsByResultId[resultId]) {
                $targetsByResultId[resultId] = $targetsByResultId[resultId].add($(element));
            } else {
                $targetsByResultId[resultId] = $(element);
            }
        });

        asyncAppend(
            {
                $target: $targetsByResultId,
                $loading: $elements.loading,
                $notifications: $elements.notifications
            },
            request
        )
            .then((result) => {
                let content = result.html || result.content;
                if (content && result.success !== false) {
                    $baseElement.trigger('success.ajax-form');
                    $elements.errorArea.attr('hidden', 'hidden');
                    addLinkClickHandler(getElementBySelector(selectors.link, $baseElement));
                    addResetHandler(getElementBySelector(selectors.reset, $baseElement));

                    let $newForm = getElementBySelector(selectors.form, $baseElement);
                    if ($newForm && $newForm.length && $newForm[0] !== $elements.form[0]) {
                        $elements.form = $newForm;
                        addSubmitHandler($elements.form);
                    }
                } else {
                    $baseElement.trigger('failed.ajax-form');
                    $elements.errorArea.attr('hidden', null);
                }

                $baseElement.trigger('fetched.ajax-form', result);
            })
            .catch(() => {});

        // Unpack json response body if the promise was created via fetch
        // Otherwise the HTTP-Server error is not caught.
        // The fetch promise itself resolves (even with a http error)
        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        ).catch((error, requestState) => {
            if ((!error || error.name !== 'AbortError') // native fetch abort
                && requestState !== 'abort') { // jquery abort
                console.error(error);
                $baseElement.trigger('failed.ajax-form');
                $elements.errorArea.attr('hidden', null);
            }
        });

        return request;
    }


    function submitForm($form) {
        return submit(
            $form.data('action') || $form.attr('action'),
            $form.data('method') || $form.attr('method'),
        )
    }

    function submit(action, method) {
        // create promise to resolve/reject in right order (important for loading-indicator with multiple submissions)
        let readyToSubmit = new Promise(function (resolve, reject) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest.catch(resolve);
                pendingRequest = null;
            } else {
                resolve();
            }
        });

        readyToSubmit.then(function () {
            if (isParsleyForm($elements.form) && !isValid($elements.form)) {
                return;
            }

            $baseElement.trigger('submit.ajax-form');

            action = action || $elements.form.data('action') || $elements.form.attr('action');
            method = method || $elements.form.data('method') || $elements.form.attr('method');
            let formDataEntries = getFormDataEntries();
            let params = new URLSearchParams(formDataEntries);

            call(options.onSubmit, {
                $element: $baseElement,
                $elements,
                options,
                formData: getFormData()
            });

            let url = new URL(location.href);
            url.searchParams.delete('page');
            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error, requestState) => {
                    if ((!error || error.name !== 'AbortError') // native fetch abort
                        && requestState !== 'abort') { // jquery abort
                        pendingRequest = null;
                    }
                });
        });
    }

    function getFormData() {
        return createFormData([$elements.form[0], ...$elements.additionalForm.toArray()]);
    }

    function getFormDataEntries() {
        return createFormDataEntries([$elements.form[0], ...$elements.additionalForm.toArray()]);
    }

    let api = {
        submit,
        getFormData,
        getFormDataEntries // todo add to doku
    };

    $baseElement.data('ajax-form', api);

    return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

export const initInScope = createInitInScope();

function createFormData(forms) {
    let formData = new FormData();
    forms.map(form => {
        for (var pair of formDataEntries(form)) {
            formData.append(...pair);
        }
    });

    return formData;
}

function createFormDataEntries(forms) {
    let formDataArray = [];
    
    forms.map(form => {
        // unchecked checkboxes and radios needs to be added manually to formDataArray
        let $selectors = $(form).find(':input[type="radio"], :input[type="checkbox"]');
        let selectorNames = [];

        $selectors.map(function() {
            selectorNames.push(this.name);
        });

        for (var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }

        let existingNames = formDataArray.map(entry => entry[0]);
        selectorNames = [...new Set(selectorNames)];

        selectorNames.forEach(function (name) {
            let newEntry = [name, ""];
            if(!existingNames.includes(name)) {
                formDataArray.push(newEntry);
            } else {
                formDataArray.filter(item => item !== newEntry);
            }
        });
    });

    return formDataArray;
}


function setInputState($form, inputState) {

    let $allInputs = $form.find('input, select, textarea');

    $allInputs.each(function () {
        let $currentInput = $(this);
        let result = findInInputState(inputState, $currentInput.attr('name'));
        let prevValue = $currentInput.val();

        if (result && result[1] !== '$$EMPTY$$') {
            $currentInput.val(result[1]); //returns the value of the input
            $currentInput.trigger('change.select2');
        } else {
            $currentInput.val('');
        }
    });

    let $radioCheckboxInputs = $form.find(':input[type="radio"], :input[type="checkbox"]');
    $radioCheckboxInputs.each(function () {
        let $currentInput = $(this);
        let result = findInInputState(inputState, $currentInput.attr('name'), $currentInput.attr('value'));
        let shouldBeChecked = !!result && result[1] !== '$$EMPTY$$';
        let wasChecked = $currentInput.is(':checked');

        if (shouldBeChecked) {
            $currentInput.prop('checked', 'checked');
        } else {
            $currentInput.prop('checked', null);
        }

        if (shouldBeChecked !== wasChecked) {
            $currentInput.trigger('changed');
        }
    });
}

function findInInputState(inputState, name, value) {
    return inputState.filter(function (currentInputState) {

        let currentInputStateName = currentInputState[0];
        let currentInputStateValue = currentInputState[1];

        if (typeof value !== 'undefined') {
            return currentInputStateName === name && currentInputStateValue === value;
        } else {
            return currentInputStateName === name;
        }
    })[0];
}



function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
